<template>
  <w-money
    v-model="value[currency]"
    :label="`Precio en ${currency.toUpperCase()}`"
    :name="`precio en ${currency.toUpperCase()}`"
    :currency="currency"
    :placeholder="`100 ${currency.toUpperCase()}`"
    class="mb-2"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          ars: null,
          creditos: null,
          usd: null,
          arsAutomatico: true,
        };
      },
    },
    currency: {
      type: String,
      default: "ars",
    },
  },
  data() {
    return {
      dolar: null,
      show: true,
      error: null,
    };
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="css" scoped></style>
