import { render, staticRenderFns } from "./index.vue?vue&type=template&id=48467d97&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WDateTime from '@/components/WDateTime.vue'
import WEntradas from '@/components/WEntradas.vue'
import WImageUpload from '@/components/WImageUpload.vue'
import WInput from '@/components/WInput.vue'
import WSelect from '@/components/WSelect.vue'
import WSwitch from '@/components/WSwitch.vue'
import WTextarea from '@/components/WTextarea.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WDateTime,WEntradas,WImageUpload,WInput,WSelect,WSwitch,WTextarea,WTopBar})
