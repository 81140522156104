<template>
  <div>
    <div class="border rounded p-3 mb-3">
      <w-input
        v-model="value.nombre"
        label="Nombre"
        name="Nombre de la entrada"
        class=""
      />
      <w-textarea
        v-model="value.description"
        label="Descripción"
        name="Descripción de la entrada"
        class=""
      />
      <w-textarea
        v-model="value.detalle"
        label="Detalle"
        name="Detalle de la entrada"
        description="Se muestra en el popup para seleccionar el tipo de entrada."
        class=""
      />
      <w-event-price
        v-model="value.precio"
        label="Precio"
        name="precio"
        placeholder="100 ARS"
        class="mb-2"
        :currency="currency"
      />
      <!-- Cupos -->
      <w-input v-model="value.cupo" label="Cupos" name="Cupos" class="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          nombre: null,
          description: null,
          price: null,
          cupo: null,
        };
      },
    },
    currency: {
      type: String,
      default: "ars",
    },
  },
  data() {
    return {
      dolar: null,
    };
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="css" scoped></style>
