<template>
  <div>
    <div class="bg-red-200 p-8" v-if="false">
      <p>DEBUG</p>
      {{ $route.params.id }}
      {{ item }}
    </div>

    <w-top-bar>Editar evento</w-top-bar>
    <div v-if="item" class="h-full p-5 bg-gray-200">
      <FeathersVuexFormWrapper :item="item" watch>
        <template v-slot="{ clone, save }">
          <div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 pr-4">
                <div class="text-lg font-medium">Información general</div>
                <div class="text-gray-700">
                  Configura el título, la descripción y el estado del evento.
                </div>
              </div>
              <div class="w-4/5">
                <w-input label="Titulo" v-model="clone.titulo" />

                <w-image-upload
                  v-model="clone.image"
                  @input="clone.cloudImage = $event"
                  class="my-5"
                  label="Imágen Presentación"
                />

                <w-textarea
                  label="Detalles del Registro"
                  v-model="clone.registro"
                />
                <!-- <w-html label="Descripcion del Evento" v-model="clone.descripcion" /> -->

                <w-textarea
                  label="Descripcion del Evento"
                  v-model="clone.descripcion"
                />

                <w-input label="Producción" v-model="clone.produccion" />
                <!-- <w-input label="Tipo" v-model="clone.tipo" /> -->

                <w-select
                  label="Modalidad"
                  class="mb-2"
                  :show-label="true"
                  v-model="clone.tipo"
                  :options="fieldTypesEstado"
                />

                <w-select
                  label="Tipo de venta"
                  class="mb-2"
                  :show-label="true"
                  v-model="clone.eventType"
                  :options="fieldTypesEvent"
                />

                <!-- Si es externo, pedir linkInscripcionExterno -->
                <w-input
                  v-if="clone.eventType == 'venta-terceros'"
                  label="Link de inscripción externo"
                  v-model="clone.linkInscripcionExterno"
                  validation="required|url"
                  description="Link a la inscripción en el sitio externo."
                />

                <div class="flex items-center my-4">
                  <input
                    id="checkbox-1"
                    type="checkbox"
                    v-model="clone.hideRegister"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-500 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="checkbox-1"
                    class="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Ocultar registro
                  </label>
                </div>

                <w-select
                  label="Estado"
                  class="mb-2"
                  :show-label="true"
                  v-model="clone.estado"
                  :options="fieldTypes"
                />
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 pr-4">
                <div class="text-lg font-medium">Detalles</div>
                <div class="text-gray-700">
                  Configura la fecha y hora del evento.
                </div>
              </div>
              <div class="w-4/5">
                <!-- Se maneja a nivel entrada ahora -->
                <!-- <w-input label="Cupos" v-model="clone.cupos" /> -->

                <div class="flex">
                  <w-date-time
                    label-day="Fecha del Evento"
                    label-hour="Horario de comienzo"
                    v-model="clone.date"
                    :duration="clone.duration"
                    @duration="clone.duration = $Product"
                    class="w-64"
                  />

                  <div>
                    <label
                      id="listbox-label"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Zona horaria
                    </label>
                    <select
                      v-model="clone.timezone"
                      class="w-64 mb-2 small-select"
                    >
                      <option
                        v-for="gmt in gmtOptions"
                        :key="gmt.gmt"
                        :value="gmt"
                      >
                        {{ gmt.label }}
                      </option>
                    </select>
                  </div>
                </div>

                <w-input label="Locacion" v-model="clone.locacion" />
                <w-input label="Dirección" v-model="clone.direccion" />
                <w-input label="Link al mapa" v-model="clone.mapLink" />
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 pr-4">
                <div class="text-lg font-medium">Precio</div>
                <div class="text-gray-700"></div>
              </div>
              <div class="w-4/5">
                <w-select
                  v-model="clone.preferredCurrency"
                  label="Moneda de preferencia"
                  name="moneda"
                  :options="[
                    {
                      key: 'ars',
                      label: 'ARS',
                    },
                    {
                      key: 'usd',
                      label: 'USD',
                    },
                    {
                      key: 'eur',
                      label: 'EUR',
                    },
                  ]"
                  class="w-64 mb-2"
                />
                <div class="flex items-center ml-2 mt-6">
                  <input
                    id="checkbox-1"
                    type="checkbox"
                    v-model="clone.conversionAutomatica"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="checkbox-1"
                    class="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Convertir valores automáticamente a otras monedas
                  </label>
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 pr-4">
                <div class="text-lg font-medium">Entradas</div>
                <div class="text-gray-700"></div>
              </div>
              <div class="w-4/5 relative">
                <!-- Boton absolute para agregar entradas -->
                <button
                  @click="clone.entradas.push({})"
                  class="absolute right-0 top-0 flex items-center font-medium rounded text-xs px-2 py-1 text-center bg-blue-500 text-white"
                >
                  <svg
                    class="w-4 h-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  Agregar
                </button>
                <div :key="i" v-for="(entrada, i) in clone.entradas">
                  <w-entradas
                    :currency="clone.preferredCurrency"
                    v-model="clone.entradas[i]"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="clone.eventType != 'venta-terceros'"
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 pr-4">
                <div class="text-lg font-medium">Metodos de pago</div>
                <div class="text-gray-700">
                  Seleccionar los metodos de pago permitidos para este evento
                </div>
              </div>
              <div class="w-4/5">
                <!-- For paypal, stripe y mercadopago -->
                <div class="flex items-center mb-2">
                  <input
                    v-model="clone.metodosPago.stripe"
                    type="checkbox"
                    id="myCheckbox"
                    class="mr-2"
                  />
                  <label for="myCheckbox" class="cursor-pointer"
                    >Stripe (USD)</label
                  >
                </div>
                <div class="flex items-center mb-2">
                  <input
                    v-model="clone.metodosPago.paypal"
                    type="checkbox"
                    id="myCheckbox"
                    class="mr-2"
                  />
                  <label for="myCheckbox" class="cursor-pointer"
                    >Paypal (USD)</label
                  >
                </div>
                <div class="flex items-center mb-2">
                  <input
                    v-model="clone.metodosPago.mercadopago"
                    type="checkbox"
                    id="myCheckbox"
                    class="mr-2"
                  />
                  <label for="myCheckbox" class="cursor-pointer"
                    >Mercado Pago (USD)</label
                  >
                </div>
              </div>
            </div>
            <div
              v-if="clone.eventType != 'venta-terceros'"
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 pr-4">
                <div class="text-lg font-medium">Preguntas</div>
                <div class="text-gray-700">
                  Agregar preguntas para el formulario de registro.
                </div>
              </div>
              <div class="w-4/5">
                <!-- Checkbox para activar variables -->

                <div class="flex items-center justify-end mb-2">
                  <w-switch v-model="clone.usarPreguntas" />
                </div>

                <div v-if="clone.usarPreguntas">
                  <input-formulario v-model="clone.preguntas" />
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 pr-4">
                <div class="text-lg font-medium">Variables</div>
                <div class="text-gray-700">
                  Desde aquí podés agregar variables para reemplazar los datos a
                  visualizar en la página del evento.
                </div>
              </div>
              <div class="w-4/5">
                <!-- Checkbox para activar variables -->

                <div class="flex items-center justify-end mb-2">
                  <w-switch v-model="clone.usarVariables" />
                </div>

                <div v-if="clone.usarVariables">
                  <!-- w-input, para fecha, hora, numero de dia, nombre corto del mes, titulo direccion, subtitulo direccion -->
                  <w-input label="Fecha" v-model="clone.variables.fecha" />
                  <w-input label="Hora" v-model="clone.variables.hora" />
                  <w-input
                    label="Numero de dia"
                    v-model="clone.variables.numeroDia"
                  />
                  <w-input
                    label="Nombre corto del mes"
                    v-model="clone.variables.nombreCortoMes"
                  />
                  <w-input
                    label="Titulo direccion"
                    v-model="clone.variables.tituloDireccion"
                  />
                  <w-input
                    label="Subtitulo direccion"
                    v-model="clone.variables.subtituloDireccion"
                  />
                  <w-input
                    label="Clases extra para la tarjeta"
                    v-model="clone.variables.classes"
                    description="Controlar las columnas de la tarjeta w-full, w-1/2, o colores de bordes border-red-500"
                  />
                  <w-input
                    label="Texto del boton para comprar entradas"
                    v-model="clone.variables.boton"
                    description="Texto del boton para comprar entradas"
                  />
                  <w-input
                    label="Texto del boton para ver más información"
                    v-model="clone.variables.boton_info"
                    description="Texto del boton para ver más información, que aparece en el listado de eventos"
                  />
                  
                </div>
              </div>
            </div>
            <div
              v-if="clone.eventType != 'venta-terceros'"
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 pr-4">
                <div class="text-lg font-medium">Estadísticas Públicas</div>
                <div class="text-gray-700">
                  Mostrar estadísticas públicas en la página del evento.
                </div>
              </div>
              <div class="w-4/5">
                <div class="flex items-center mb-2">
                  <input
                    v-model="clone.mostrarEstadisticas"
                    type="checkbox"
                    id="mostrarEstadisticas"
                    class="mr-2"
                  />
                  <label for="mostrarEstadisticas" class="cursor-pointer">
                    Mostrar estadísticas públicas
                  </label>
                </div>
                <w-input
                    v-if="clone.mostrarEstadisticas"
                    class="mt-4"
                    label="Cantidad de entradas disponibles"
                    v-model="clone.variables.estadisticas_disponibles"
                  />
                <w-input
                v-if="clone.mostrarEstadisticas"
                class="mt-4"
                    label="Título de la tarjeta de estadísticas"
                    v-model="clone.variables.estadisticas_titulo"
                  />
                  <w-input
                  v-if="clone.mostrarEstadisticas"
                    label="Cuerpo de la tarjeta de estadísticas"
                    v-model="clone.variables.estadisticas_cuerpo"
                  />
              </div>
            </div>

            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Enlace Directo</div>
                <div class="text-gray-700">
                  Enlace directo a la página del producto.
                </div>
              </div>
              <div class="flex-grow">
                <div
                  ref="container"
                  class="relative flex items-center flex-grow p-2 text-xs font-medium text-indigo-900 bg-indigo-400 bg-opacity-50 rounded"
                >
                  <div>
                    {{ directLink }}
                  </div>
                  <svg
                    v-if="!copied"
                    v-tooltip="'Copiar dirección al portapapeles'"
                    @click="copyDirectLink()"
                    class="absolute w-5 h-5 cursor-pointer right-2 hover:text-indigo-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                    />
                  </svg>
                  <svg
                    v-else
                    class="absolute w-5 h-5 cursor-pointer right-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div
                  v-if="!editPermalink"
                  class="mt-1 text-xs underline cursor-pointer text-marine-500"
                  @click="editPermalink = true"
                >
                  Editar enlace permanente
                </div>
                <w-input
                  v-if="editPermalink"
                  label="Enlace Permanente"
                  description="Solo caracteres y guiones, sin espacios."
                  class="mt-3"
                  v-model="clone.slug"
                />
              </div>
            </div>

            <div class="flex justify-end py-2">
              <div
                v-if="saved"
                class="flex items-center text-sm font-medium text-green-600"
              >
                <div>Cambios guardados</div>
                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-green-600 transition duration-150 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div
                v-if="saving"
                class="flex items-center text-sm font-medium text-gray-600"
              >
                <div>Guardando cambios</div>

                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-gray-600 transition duration-150 ease-in-out animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </div>

              <button
                @click="doSave(save)"
                class="flex items-center px-4 py-2 text-sm font-bold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
              >
                Guardar
              </button>
            </div>
          </div>
        </template>
      </FeathersVuexFormWrapper>
    </div>
  </div>
</template>
<script>
import { FeathersVuexFormWrapper, makeFindMixin } from "feathers-vuex";
import InputFormulario from "@/components/nuevo/InputFormulario.vue";
export default {
  name: "evento-id", // Change the component name to match the new context
  components: {
    FeathersVuexFormWrapper,
    InputFormulario,
  },
  mixins: [makeFindMixin({ service: "eventos" })], // Update the service name to "events"
  computed: {
    id() {
      return this.$route.params.id;
    },
    item() {
      const { Eventos } = this.$FeathersVuex.api; // Update the model to "Event"

      return this.id === "new" ? new Eventos() : Eventos.getFromStore(this.id); // Update the model to "Event"
      // return Eventos.getFromStore(this.id); // Update the model to "Event"
    },
    eventCategoryUrl() {
      // Update the property names to match your new event context
      let category = this.item.category;

      if (this.item.categories && this.item.categories.length > 0)
        category = this.item.categories[0];

      if (category) return category;
      return "default-category";
    },
    directLink() {
      if (!this.item) return null;
      return "https://tienda.orsai.org/eventos/" + this.item.slug;
    },
    categoriesParams() {
      return {
        query: {
          $sort: {
            name: -1,
          },
        },
      };
    },
  },
  watch: {
    id: {
      handler(val) {
        if (val === "new") {
          return;
        }
        const { Eventos } = this.$FeathersVuex.api; // Update the model to "Event"
        const existingRecord = Eventos.getFromStore(val); // Update the model to "Event"

        // Si tiene variables, seteamos usarVariables = true
        if (existingRecord && existingRecord.variables) {
          existingRecord.usarVariables = true;
        }

        if (!existingRecord) {
          Eventos.get(val); // Update the model to "Event"
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      customs: [],
      gmtOptions: [
        {
          gmt: "-3",
          label: "Argentina",
        },
        {
          gmt: "+2",
          label: "España",
        },
      ],
      fieldTypes: [
        // { key: "number", label: "Número" },
        { key: "en-venta", label: "Inscripción abierta" },
        { key: "agotado", label: "Agotado" },
        { key: "proximament", label: "Proximamente" },
        { key: "cerrado", label: "Finalizado" },
        { key: "cancelado", label: "Cancelado" },
      ],
      fieldTypesEstado: [
        //evento en linea y evento precencial
        { key: "streamings", label: "Evento en Linea" },
        { key: "presencial", label: "Evento Presencial" },
      ],
      fieldTypesEvent: [
        //evento en linea y evento precencial
        { key: "venta-orsai", label: "Venta de Orsai" },
        { key: "venta-terceros", label: "Venta de terceros" },
      ],
      editPermalink: false,
      saved: false,
      copied: false,
      saving: false,
      deliveryTimes: [
        { key: 0, label: "En el día" },
        { key: "3-days", label: "1-3 Días" },
        { key: "1-week", label: "1 Semana" },
        { key: "2-week", label: "15 Días" },
        { key: "4-week", label: "15-30 Días" },
      ],
    };
  },
  methods: {
    remove(event, index) {
      event.customs.splice(index, 1);
    },
    calcVolumetric(event) {
      event.volumetric = 100;
      console.log(event);
      this.$nextTick();
    },
    copyDirectLink() {
      let container = this.$refs.container;
      this.$copyText(this.directLink, container);
      this.copied = true;
      const self = this;
      setTimeout(() => {
        self.copied = false;
      }, 2000);
    },
    async doSave(save) {
      this.saved = false;
      this.saving = true;
      let event = await save();
      this.saving = false;
      this.saved = true;
      const self = this;
      setTimeout(() => {
        self.saved = false;
      }, 3000);
      this.handleSaveResponse(event);
    },
    handleSaveResponse(savedEvent) {
      console.log("savedEvent", savedEvent);
      console.log("handleSaveResponse");
      if (this.id === "new") {
        this.$router.push({ params: { id: savedEvent._id } });
      }
    },
  },
};
</script>


<style lang="css">
.small-select {
  font-size: 0.75rem;
  padding: 0px 0.5rem;
  height: 2rem;
  border-radius: 0.375rem;
  border-color: #e5e7eb;
}
</style>
