<template>
  <div>
    <div class="flex space-x-2 items-center justify-end border-b pb-2 mb-2">
      <button
        class="flex items-center font-medium rounded text-xs px-2 py-1 text-center bg-blue-500 text-white"
        @click="addInputQuestion"
      >
        <svg
          class="w-4 h-4 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
        Texto
      </button>
      <button
        class="flex items-center font-medium rounded text-xs px-2 py-1 text-center bg-blue-500 text-white"
        @click="addRadioQuestion"
      >
        <svg
          class="w-4 h-4 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
        Radio
      </button>
    </div>

    <div v-for="(question, index) in questions" :key="index">
      <div
        v-if="question.type === 'input'"
        class="border rounded p-3 flex space-x-4 items-center my-2"
      >
        <w-input
          class="flex-1"
          :label="'Pregunta ' + (index + 1)"
          v-model="question.label"
        />
        <div>
          <label class="block text-xs text-gray-700">Validación</label>
          <select
            v-model="question.validation"
            class="w-32 rounded px-2 py-1 border-gray-400 text-sm"
          >
            <option value="">Ninguna</option>
            <option value="required">Requerido</option>
            <option value="email">Email</option>
            <option value="number">Número</option>
          </select>
        </div>
        <div
          v-tooltip="'Eliminar Pregunta'"
          @click="removeQuestion(index)"
          class="flex text-gray-400 items-center text-xs text-left cursor-pointer hover:text-red-600 rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="w-4 h-4"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
      </div>

      <div
        v-if="question.type === 'radio'"
        class="relative border rounded p-3 my-2"
      >
        <w-input
          class="flex-1"
          :label="'Pregunta ' + (index + 1)"
          v-model="question.label"
        />
        <!-- Mini titulo para opción -->
        <div class="text-xs text-gray-900 border-b mt-4 pb-2 mb-2">
          Opciones
        </div>
        <div
          v-for="(option, optionIndex) in question.options"
          :key="optionIndex"
          class="flex space-x-4 items-center"
        >
          <w-input
            class="flex-1"
            :label="'Opción ' + (optionIndex + 1)"
            description="Se mostrará como una opción de respuesta."
            v-model="option.label"
          />
          <div
            v-tooltip="'Eliminar Opción'"
            @click="removeOption(index, optionIndex)"
            class="flex text-gray-400 items-center text-xs text-left cursor-pointer hover:text-red-600 rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-4 h-4"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-end mt-2">
          <button
            class="flex items-center font-medium rounded text-xs px-2 py-1 text-center bg-blue-500 text-white"
            @click="addOption(index)"
          >
            <svg
              class="w-4 h-4 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            Opción
          </button>
        </div>

        <div
          v-tooltip="'Eliminar Pregunta'"
          @click="removeQuestion(index)"
          class="absolute top-0 right-0 m-2 flex text-gray-400 items-center text-xs text-left cursor-pointer hover:text-red-600 rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="w-4 h-4"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [],
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    questionArray() {
      return this.questions.map((question) => {
        if (question.type === "input") {
          return {
            label: question.label,
            name: question.label.toLowerCase().replace(/\s/g, "-"),
            validation: question.validation,
          };
        } else if (question.type === "radio") {
          return {
            label: question.label,
            options: question.options.map((option) => ({
              label: option.label,
              value: option.value,
            })),
          };
        }
      });
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.questions = value;
      },
    },
    questions: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    addInputQuestion() {
      this.questions.push({ type: "input", label: "", validation: "" });
    },
    addRadioQuestion() {
      this.questions.push({ type: "radio", label: "", options: [] });
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    addOption(questionIndex) {
      this.questions[questionIndex].options.push({ label: "", value: "" });
    },
    removeOption(questionIndex, optionIndex) {
      this.questions[questionIndex].options.splice(optionIndex, 1);
    },
  },
};
</script>